.home-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
  }
  .home-header {
    width: 100%;
    display: flex;
    z-index: 100;
    position: fixed;
    align-items: center;
    flex-direction: column;
    background-color: var(--dl-color-gray-white);
  }
  .home-desktop-menu {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
  .home-links {
    flex: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 5%;
  }
  .home-nav22 {
    margin-left: var(--dl-space-space-unit);
  }
  .home-nav32 {
    margin-left: var(--dl-space-space-unit);
  }
  .home-nav42 {
    margin-left: var(--dl-space-space-unit);
  }
  .home-nav52 {
    margin-left: var(--dl-space-space-unit);
  }
  .home-nav62 {
    margin-left: var(--dl-space-space-unit);
  }
  .home-burger-menu {
    display: none;
  }
  .home-icon {
    width: var(--dl-size-size-xsmall);
    cursor: pointer;
    height: var(--dl-size-size-xsmall);
  }
  .home-mobile-menu1 {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    display: none;
    padding: 32px;
    z-index: 100;
    position: absolute;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-nav {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-top {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-threeunits);
    justify-content: space-between;
  }
  .home-close-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-icon02 {
    width: var(--dl-size-size-xsmall);
    cursor: pointer;
    height: var(--dl-size-size-xsmall);
  }
  .home-links1 {
    flex: 0 0 auto;
    display: flex;
    align-self: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-nav121 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav221 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav321 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav421 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav521 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav621 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-buttons {
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .home-icon04 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-right: var(--dl-space-space-twounits);
  }
  .home-icon06 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-right: var(--dl-space-space-twounits);
  }
  .home-icon08 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .home-hero {
    flex: 0 0 auto;
    width: 100%;
    height: 70vh;
    display: flex;
    margin-top: 96px;
    align-items: flex-start;
    justify-content: center;
  }
  .home-container1 {
    gap: var(--dl-space-space-oneandhalfunits);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-hero-heading {
    max-width: 800px;
    text-align: center;
  }
  .home-hero-sub-heading {
    font-size: 18px;
    text-align: center;
  }
  .home-btn-group {
    gap: var(--dl-space-space-oneandhalfunits);
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .home-features {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-features1 {
    gap: var(--dl-space-space-threeunits);
    width: 100%;
    display: flex;
    max-width: var(--dl-size-size-maxwidth);
    align-items: center;
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-fourunits);
    justify-content: flex-start;
  }
  .home-container2 {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-features-heading {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-features-sub-heading {
    font-size: 18px;
    text-align: center;
  }
  .home-container3 {
    width: 100%;
    display: grid;
    grid-gap: var(--dl-space-space-oneandhalfunits);
    grid-template-columns: 1fr 1fr;
  }
  .home-pricing {
    flex: 0 0 auto;
    width: 100%;
    border: 2px dashed rgba(120, 120, 120, 0.4);
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-banner {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-banner-heading {
    text-align: center;
  }
  .home-banner-sub-heading {
    max-width: var(--dl-size-size-maxwidth);
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-faq {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-faq1 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    display: flex;
    max-width: var(--dl-size-size-maxwidth);
    align-items: flex-start;
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-container4 {
    display: flex;
    max-width: 35%;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-text48 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-text49 {
    text-align: left;
  }
  .home-container5 {
    gap: var(--dl-space-space-oneandhalfunits);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-footer {
    flex: 0 0 auto;
    width: 100%;
    position: static;
    bottom: 0;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .App-profile-photo {
    border-radius: 100%;
    margin-top: 0;
    height: 300px;
    width: 300px;
    overflow: hidden;
    background-size: cover;
    background-position: center;

  }

  .para-justify {
    text-align: justify;
  }

  .App-header {
    margin-top: 10vh;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
  }
  
  .App-header h1,
  .App-header-occupation {
    margin: 0;
    padding: 0.3rem;
  }
  
  .App-header-occupation {
    font-size: 1.5em;
  }

  .App-story {
    margin: 0 auto;
    min-height: 80vh;
    max-width: 80vw;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .App-story p {
    margin: 1rem auto;
    font-size: 1.5rem;
  }

  img.rounded-circle {
    border:  16px var(--secondary);
    width: 200px;
    padding-bottom: 15px;
    }

  .my-2 {
    display: flex;
    flex:space-between;
    justify-content: center;
    }

  .home-container6 {
    gap: var(--dl-space-space-unit);
    display: flex;
    max-width: var(--dl-size-size-maxwidth);
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-nav1 {
    flex: 0 0 auto;
    display: flex;
    margin-top: 0px;
    align-items: center;
    flex-direction: row;
  }
  .home-nav222 {
    margin-left: var(--dl-space-space-unit);
  }
  .home-nav322 {
    margin-left: var(--dl-space-space-unit);
  }
  .home-nav422 {
    margin-left: var(--dl-space-space-unit);
  }
  .home-nav522 {
    margin-left: var(--dl-space-space-unit);
  }
  .home-nav622 {
    margin-left: var(--dl-space-space-unit);
  }
  .home-separator {
    flex: 0 0 auto;
    width: 100%;
    height: 0px;
    display: flex;
    margin-top: var(--dl-space-space-twounits);
    align-items: flex-start;
    margin-left: 0px;
    border-color: var(--dl-color-gray-900);
    border-style: solid;
    border-width: 1px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: row;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .home-container7 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .home-icon-group1 {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .home-icon10 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-twounits);
  }
  .home-icon12 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-twounits);
  }
  .home-icon14 {
    width: 24px;
    height: 24px;
  }
  @media(max-width: 991px) {
    .home-hero1 {
      flex-direction: column;
    }
    .home-container1 {
      align-items: center;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-twounits);
      padding-right: 0px;
    }
    .home-hero-heading {
      text-align: center;
    }
    .home-hero-sub-heading {
      text-align: center;
      padding-left: var(--dl-space-space-threeunits);
      padding-right: var(--dl-space-space-threeunits);
    }
    .home-features-sub-heading {
      text-align: center;
      padding-left: var(--dl-space-space-threeunits);
      padding-right: var(--dl-space-space-threeunits);
    }
    .home-banner-sub-heading {
      max-width: 100%;
    }
    .home-text49 {
      text-align: center;
      padding-left: var(--dl-space-space-threeunits);
      padding-right: var(--dl-space-space-threeunits);
    }
  }
  @media(max-width: 767px) {
    .home-navbar-interactive {
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
    }
    .home-desktop-menu {
      display: none;
    }
    .home-burger-menu {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .home-nav121 {
      margin-bottom: var(--dl-space-space-unit);
    }
    .home-nav221 {
      margin-bottom: var(--dl-space-space-unit);
    }
    .home-nav321 {
      margin-bottom: var(--dl-space-space-unit);
    }
    .home-nav421 {
      margin-bottom: var(--dl-space-space-unit);
    }
    .home-nav521 {
      margin-bottom: var(--dl-space-space-unit);
    }
    .home-nav621 {
      margin-bottom: var(--dl-space-space-unit);
    }
    .home-hero1 {
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
    }
    .home-hero-sub-heading {
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
    .home-features1 {
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
    }
    .home-features-sub-heading {
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
    .home-container3 {
      grid-template-columns: 1fr;
    }
    .home-banner1 {
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
    }
    .home-banner-sub-heading {
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
    .home-faq1 {
      flex-direction: column;
    }
    .home-container4 {
      max-width: 100%;
      align-self: center;
      align-items: center;
      justify-content: center;
    }
    .home-text49 {
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
    .home-footer1 {
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
    }
    .home-separator {
      margin-top: var(--dl-space-space-oneandhalfunits);
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
    .home-container7 {
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
    }
    .home-text52 {
      margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
  }
  @media(max-width: 479px) {
    .home-navbar-interactive {
      padding: var(--dl-space-space-unit);
    }
    .home-mobile-menu1 {
      padding: 16px;
    }
    .home-hero1 {
      padding-top: var(--dl-space-space-twounits);
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
      padding-bottom: var(--dl-space-space-twounits);
    }
    .home-container1 {
      margin-bottom: var(--dl-space-space-unit);
    }
    .home-btn-group {
      flex-direction: column;
    }
    .home-features1 {
      padding-top: var(--dl-space-space-twounits);
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
      padding-bottom: var(--dl-space-space-twounits);
    }
    .home-banner1 {
      padding-top: var(--dl-space-space-twounits);
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
      padding-bottom: var(--dl-space-space-twounits);
    }
    .home-footer1 {
      padding: var(--dl-space-space-unit);
    }
    .home-separator {
      margin-top: var(--dl-space-space-oneandhalfunits);
      margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
    .home-container7 {
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
    }
    .home-text52 {
      text-align: center;
      margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
  }
  