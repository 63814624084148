:root {
    --dl-color-gray-500: #595959;
    --dl-color-gray-700: #999999;
    --dl-color-gray-900: #D9D9D9;
    --dl-size-size-large: 144px;
    --dl-size-size-small: 48px;
    --dl-color-danger-300: #A22020;
    --dl-color-danger-500: #BF2626;
    --dl-color-danger-700: #E14747;
    --dl-color-gray-black: #000000;
    --dl-color-gray-white: #FFFFFF;
    --dl-size-size-medium: 96px;
    --dl-size-size-xlarge: 192px;
    --dl-size-size-xsmall: 16px;
    --dl-space-space-unit: 16px;
    --dl-color-primary-100: #003EB3;
    --dl-color-primary-300: #0074F0;
    --dl-color-primary-500: #14A9FF;
    --dl-color-primary-700: #85DCFF;
    --dl-color-success-300: #199033;
    --dl-color-success-500: #32A94C;
    --dl-color-success-700: #4CC366;
    --dl-size-size-xxlarge: 288px;
    --dl-size-size-maxwidth: 1400px;
    --dl-radius-radius-round: 50%;
    --dl-color-custom-accent1: #C9B8F9;
    --dl-space-space-halfunit: 8px;
    --dl-space-space-sixunits: 96px;
    --dl-space-space-twounits: 32px;
    --dl-color-custom-primary1: #FBA834;
    --dl-color-custom-primary2: #6466A8;
    --dl-radius-radius-radius2: 2px;
    --dl-radius-radius-radius4: 4px;
    --dl-radius-radius-radius8: 8px;
    --dl-space-space-fiveunits: 80px;
    --dl-space-space-fourunits: 64px;
    --dl-space-space-threeunits: 48px;
    --dl-color-custom-secondary1: #333A73;
    --dl-color-custom-neutral-dark: #080A45;
    --dl-color-custom-neutral-light: #FFFFFF;
    --dl-space-space-oneandhalfunits: 24px;
  }
  .button {
    color: var(--dl-color-gray-black);
    display: inline-block;
    padding: 0.5rem 1rem;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    border-radius: 4px;
    background-color: var(--dl-color-gray-white);
  }
  .input {
    color: var(--dl-color-gray-black);
    cursor: auto;
    padding: 0.5rem 1rem;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    border-radius: 4px;
    background-color: var(--dl-color-gray-white);
  }
  .textarea {
    color: var(--dl-color-gray-black);
    cursor: auto;
    padding: 0.5rem;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    border-radius: 4px;
    background-color: var(--dl-color-gray-white);
  }
  .list {
    width: 100%;
    margin: 1em 0px 1em 0px;
    display: block;
    padding: 0px 0px 0px 1.5rem;
    list-style-type: none;
    list-style-position: outside;
  }
  .list-item {
    display: list-item;
  }
  .teleport-show {
    display: flex !important;
    transform: none !important;
  }
  .buttonFilled {
    color: var(--dl-color-custom-neutral-light);
    transition: 0.3s;
    font-family: Bricolage Grotesque;
    font-weight: bold;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    border-radius: 8px;
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-halfunit);
    background-color: var(--dl-color-custom-primary1);
  }
  .buttonFilled:hover {
    background-color: var(--dl-color-custom-primary2);
  }
  .buttonFilledSecondary {
    color: var(--dl-color-custom-neutral-light);
    transition: 0.3s;
    font-family: Bricolage Grotesque;
    font-weight: bold;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    border-radius: 8px;
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-halfunit);
    background-color: var(--dl-color-custom-primary1);
  }
  .buttonFilledSecondary:hover {
    background-color: var(--dl-color-custom-primary2);
  }
  .buttonOutline {
    color: var(--dl-color-custom-neutral-dark);
    border: 1px solid;
    font-family: Bricolage Grotesque;
    font-weight: bold;
    padding-top: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-custom-neutral-dark);
    padding-left: var(--dl-space-space-oneandhalfunits);
    border-radius: 8px;
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .buttonOutline:hover {
    color: var(--dl-color-custom-primary1);
    border-color: var(--dl-color-custom-primary1);
  }
  .buttonFlat {
    color: var(--dl-color-custom-neutral-dark);
    font-family: Bricolage Grotesque;
    font-weight: bold;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .buttonFlat:hover {
    color: var(--dl-color-custom-accent1);
  }
  .featuresContainer {
    flex: 0 0 auto;
    color: var(--dl-color-custom-neutral-dark);
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-custom-secondary1);
  }
  .featuresCard {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
    background-color: #387ADF;
  }
  .featuresIcon {
    fill: var(--dl-color-custom-neutral-dark);
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;
    margin-right: var(--dl-space-space-oneandhalfunits);
  }

  

  .freePricingCard {
    gap: var(--dl-space-space-twounits);
    color: var(--dl-color-custom-neutral-dark);
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-twounits);
    max-width: 450px;
    min-height: 450px;
    align-items: flex-start;
    flex-direction: column;
    background-color: var(--dl-color-custom-accent1);
  }
  .basicPricingCard {
    gap: var(--dl-space-space-twounits);
    color: var(--dl-color-custom-neutral-dark);
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-twounits);
    max-width: 450px;
    min-height: 450px;
    align-items: flex-start;
    flex-direction: column;
    background-color: var(--dl-color-custom-accent1);
  }
  .proPricingCard {
    gap: var(--dl-space-space-twounits);
    color: var(--dl-color-custom-neutral-dark);
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-twounits);
    max-width: 450px;
    min-height: 450px;
    align-items: flex-start;
    flex-direction: column;
    background-color: var(--dl-color-custom-accent1);
  }
  .faqContainer {
    flex: 0 0 auto;
    color: var(--dl-color-custom-neutral-light);
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    background-color: var(--dl-color-custom-primary1);
  }
  .navbarContainer {
    color: var(--dl-color-custom-neutral-dark);
    width: 100%;
    display: flex;
    position: sticky;
    align-items: center;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: space-between;
    background-color: var(--dl-color-custom-neutral-light);
  }
  .mobileMenu {
    color: var(--dl-color-custom-neutral-dark);
    background-color: var(--dl-color-custom-neutral-light);
  }
  .heroContainer {
    color: var(--dl-color-custom-neutral-dark);
    width: 100%;
    display: flex;
    min-height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-sixunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-sixunits);
    justify-content: center;
    background-color: var(--dl-color-custom-neutral-light);
  }
  .pricingContainer {
    gap: var(--dl-space-space-twounits);
    color: var(--dl-color-custom-neutral-dark);
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-fourunits);
    justify-content: center;
    background-color: var(--dl-color-custom-neutral-light);
  }
  .bannerContainer {
    color: var(--dl-color-custom-neutral-dark);
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-sixunits);
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-sixunits);
    justify-content: space-between;
    background-color: var(--dl-color-custom-neutral-light);
  }
  .footerContainer {
    color: var(--dl-color-custom-neutral-dark);
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
    background-color: var(--dl-color-custom-neutral-light);
  }
  .socialIcons {
    fill: var(--dl-color-custom-neutral-dark);
  }
  .Content {
    font-size: 16px;
    font-family: Inter;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .Heading {
    font-size: 32px;
    font-family: Inter;
    font-weight: 700;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .logo {
    font-size: 2em;
    font-family: Bricolage Grotesque;
    font-weight: 700;
    line-height: 150%;
    text-transform: uppercase;
  }
  .heading1 {
    font-size: 48px;
    text-align: center;
    font-family: Bricolage Grotesque;
    font-weight: 700;
    line-height: 150%;
  }
  .heading2 {
    font-size: 35px;
    font-family: Bricolage Grotesque;
    font-weight: 600;
    line-height: 150%;
  }
  .heading3 {
    font-size: 20px;
    text-align: center;
    font-family: Bricolage Grotesque;
    font-weight: 600;
    line-height: 150%;
  }
  .bodyLarge {
    font-size: 18px;
    font-family: Bricolage Grotesque;
    font-weight: 400;
    line-height: 150%;
  }
  .bodySmall {
    font-size: 16px;
    font-family: Bricolage Grotesque;
    font-weight: 400;
    line-height: 150%;
    color: #000000;
  }

  .whiteText{
    color: #FFFFFF;
  }
  .overline {
    font-size: 12px;
    font-family: Bricolage Grotesque;
    font-weight: 700;
    line-height: 150%;
    text-transform: uppercase;
  }

  .shrink {
    transition: height 0.3s ease; /* Add transition effect for smooth animation */
  }

  


.card{
  transition:0.3s;
}

.card:hover {
  transform: scale(1.15);
}

.text-justify {
  text-align: justify;
}